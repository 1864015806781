import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa"

const PrevNext = ({ prev, next }) => (
  <div
    className={classNames(
      "d-flex mt-5",
      {
        "justify-content-between": prev && next,
      },
      { "justify-content-end": !prev && next }
    )}
  >
    {prev && (
      <Link className="btn btn-outline-secondary" to={prev}>
        <FaAngleLeft size="20" /> Prev project
      </Link>
    )}

    {next && (
      <Link className="btn btn-outline-secondary" to={next}>
        Next project
        <FaAngleRight size="20" />
      </Link>
    )}
  </div>
)

export default PrevNext
