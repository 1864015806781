import * as styles from "./project.module.css"

import Image from "../../components/image/image"
import Layout from "../../components/layout"
import PrevNext from "../../components/prev-next/prev-next"
import ProjectFacts from "../../components/project-facts/project-facts"
import React from "react"
import Section from "../../components/section/section"
import Seo from "../../components/seo"
import Stage from "../../components/stage/stage"
import competitiveAnalysisImg from "../../images/projects/deputy-product-tour/competitive-analysis.svg"
import dataAnalysisImg from "../../images/projects/deputy-product-tour/data-analysis.png"
import internalFeedbackImg from "../../images/projects/deputy-product-tour/internal-feedback.png"
import introImg from "../../images/projects/deputy-product-tour/intro.jpg"
import keyFindingsImg from "../../images/projects/deputy-product-tour/key-findings.jpg"
import recommendationsImg from "../../images/projects/deputy-product-tour/recommendations.png"
import userFeedbackImg from "../../images/projects/deputy-product-tour/user-feedback.png"
import userFeedbackPhotoImg from "../../images/projects/deputy-product-tour/user-feedback-photo.png"
import userJourneyImg from "../../images/projects/deputy-product-tour/user-journey.jpg"

const DeputyProductTour = () => (
  <Layout>
    <Seo title="Deputy product tour research" />

    <Section>
      <>
        <h1>Product Tour Research</h1>
        <p>
          Deputy is a staff rostering software that helps manage schedules from
          any device, record precise timesheets and streamline award wage
          calculations. This is a Product Tour Research I did to get to know
          trial users, their journey, needs and in which context they are using
          Deputy free trial.
        </p>

        <ProjectFacts
          className="mb-4 border-top"
          name="Deputy"
          type="Research"
          year="2018"
        />

        <Image img={introImg} />

        <div className="row">
          <div className="col-12 col-md-6 border-right pr-md-5">
            <h2 className="mb-4">Business challenge</h2>

            <p>
              It was not clear to users what they should focus their energy on
              when they land on the Deputy web app for the first time. They were
              not provided with a relevant and helpful experience.
            </p>
          </div>
          <div className="col-12 col-md-6 pl-md-5">
            <h2 className="mb-4">My role</h2>

            <p>
              I was a part of a Growth team and responsible for identifying
              system problems and uncovering new opportunities. I completed the
              user research and played it back to the team and key stakeholders,
              highlighting key findings and recommendations. I worked very
              closely with my team (PM and engineers) who assisted with user
              interviews and testing.
            </p>
          </div>
        </div>
      </>
    </Section>

    <Section
      heading="Research key findings"
      className={styles.blueImgBackground}
    >
      <ul className="mb-5">
        <li>
          Users need a clearer starting point, so they can immediately explore
          what Deputy has to offer
        </li>
        <li>
          Users would discover product value if we presented relevant features
          earlier in their journey
        </li>
        <li>
          Users prefer to start from integration to reduce manual entry and to
          ensure accuracy
        </li>
        <li>
          Users will have a more valuable experience if they can roster their
          own employees
        </li>
      </ul>
    </Section>

    <Section heading="Recommendations">
      <Image img={recommendationsImg} />
    </Section>

    <Section heading="What did the process look like?">
      <>
        <p>
          Without many pre-existing insights, the project kicked off from
          various stakeholder, customer support and growth team’s discussions to
          get a better understanding of the current customer approach and
          company-wide experiences.
        </p>

        <div className="d-flex justify-content-between flex-column flex-sm-row mb-5">
          <Stage stage="Strategy">
            <ul className="text-left">
              <li>Business goals</li>
              <li>Stakeholder discussions</li>
              <li>Customer support team discussions</li>
            </ul>
          </Stage>
          <Stage stage="Discovery">
            <ul className="text-left">
              <li>Competitor analysis</li>
              <li>Data analytics review</li>
              <li>User interviews</li>
              <li>Usability testing</li>
            </ul>
          </Stage>
          <Stage stage="Analysis">
            <ul className="text-left">
              <li>Experience map</li>
              <li>User flows</li>
              <li>Feedback synthesis</li>
              <li>Data analysis</li>
            </ul>
          </Stage>
          <Stage stage="Report">
            <ul className="text-left">
              <li>Key findings</li>
              <li>Recommendations</li>
              <li>Play back</li>
            </ul>
          </Stage>
          <Stage stage="Next steps">
            <ul className="text-left">
              <li>Jobs to be done</li>
              <li>Team planning</li>
            </ul>
          </Stage>
        </div>

        <div className="mb-5">
          <h3>Business objectives</h3>
          <ul>
            <li>Increase conversion rate from trial to paying customers</li>
            <li>
              Understand user goals and expectations on Deputy’s free trial
            </li>
            <li>
              Create a tailored experience for each customer within their
              relevant industry
            </li>
          </ul>
        </div>
      </>
    </Section>

    <Section heading="Data analysis" className={styles.peachBackground}>
      <Image img={dataAnalysisImg} />
    </Section>

    <Section heading="Internal feedback">
      <>
        <p>
          By reaching out to customer support and onboarding teams I was looking
          to understand the following :
        </p>
        <ul>
          <li>Who and why created a free trial?</li>
          <li>Did users have any expectations once the trial was created?</li>
          <li>
            What questions or requests did users have during onboarding
            walk-through?
          </li>
        </ul>
        <p className="mb-5">
          The team was incredibly helpful in sharing their insights, they also
          invited me to partake in live demo sessions which they carry out with
          trial users. These sessions helped me to uncover a few common
          patterns.
        </p>

        <Image img={internalFeedbackImg} />
      </>
    </Section>

    <Section heading="Competitive analysis" className={styles.blueBackground}>
      <>
        <p className="mb-5">
          The purpose of this analysis was to determine what our competitors and
          other SaaS companies do to assist new trials.
        </p>

        <Image img={competitiveAnalysisImg} caption="Competitive analysis" />
      </>
    </Section>

    <Section heading="User feedback">
      <>
        <p>
          A round of usability testing and interviews were conducted with Deputy
          and non-Deputy customers to help us understand their journey, actions
          and behaviour as well as validating our hypothesis.
        </p>
        <p>Hypothesis:</p>

        <ul className="mb-5">
          <li>
            After signing up, users are redirected to the Schedule tab as it is
            the best way to show the value the application can bring to their
            business
          </li>
          <li>
            ‘Unlock more free days’ gamification - it is not 100% clear how it
            works and what’s offered
          </li>
          <li>Users find the walkthrough and pop-ups very helpful</li>
          <li> Setting up business locations require more guidance</li>
          <li>
            Users prefer Help Portal rather than Intercom chat when searching
            for answers
          </li>
          <li>
            Integration should be a part of the onboarding process to assist
            with set up
          </li>
        </ul>

        <Image
          img={userFeedbackPhotoImg}
          caption="User feedback during usability testing"
        />

        <Image img={userFeedbackImg} />
      </>
    </Section>

    <Section heading="User journey">
      <>
        <p className="mb-5">
          I mapped out a step-by-step screen flow to illustrate what it takes to
          create a free Deputy trial on a web app.
        </p>

        <Image img={userJourneyImg} caption="Web and mobile screen flow" />
      </>
    </Section>

    <Section heading="Research key findings" className={styles.blueBackground}>
      <>
        <p className="mb-4">
          After synthesising everyone’s feedback, data insights and mapping out
          user flows, I was able to identify the main pain points and common
          user patterns. To share the findings, I organised a session with the
          Growth team and a couple of stakeholders to go through research
          details and questions. During the session, we grouped the main pain
          points into 3 categories and brainstormed various possible solutions.
          Having different experts in the room (engineers, product, design and
          business) helped us to uncover several opportunities and small wins
          within them.
        </p>

        <Image img={keyFindingsImg} />

        <p>
          Check out 'Onboarding Experiments' project I designed to improve trial
          user journey.
        </p>
        <PrevNext
          prev="/project/safety-culture-onboarding"
          next="/project/deputy-growth-experiments"
        />
      </>
    </Section>
  </Layout>
)

export default DeputyProductTour
