import * as styles from "./stage.module.css"

import React from "react"

const Stage = ({ stage, children }) => (
  <div className={styles.stage}>
    <div className={styles.circle}>{stage}</div>
    <div className={styles.description}>{children}</div>
  </div>
)

export default Stage
