import * as styles from "./project-facts.module.css"

import React from "react"

const ProjectFacts = ({ className, name, type, year }) => (
  <div className={`d-flex ${className}`}>
    <span className={styles.fact}>
      <div className={styles.name}>Client</div>
      <div>{name}</div>
    </span>
    <span className={styles.fact}>
      <div className={styles.name}>Project type</div>
      <div>{type}</div>
    </span>
    <span className={styles.fact}>
      <div className={styles.name}>Year</div>
      <div>{year}</div>
    </span>
  </div>
)
export default ProjectFacts
